import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import List from "../views/List/List.vue";
import ListDetail from "../views/ListDetail/ListDetail.vue";
import Custom from "../views/Custom/Custom.vue";
import Experiment from "../views/Experiment/Experiment.vue";
import Personal from "../views/Personal/index.vue";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: '/list',
    children:[
      {
        path: '/list',
        name: 'List',
        component: List,
        meta: { title: '首页' }
      },
      {
        path: "/listDetail",
        name: "ListDetail",
        component: ListDetail,
        meta: { title: '详情' }
      },
      {
        path: "/personal",
        name: "Personal",
        component: Personal,
        meta: { title: '个人中心' }
      },
    ]
  },
  {
    path: "/experiment",
    name: "Experiment",
    component: Experiment,
    meta: { title: '实验' }
  },
];

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
});

export default router;
