import {request} from './request'

//获取统一认证地址
export function authUrl(data){
    return request({
        url:"/sso/getAuthUrl",
        method:'post',
        data
    })
}

//退出
export function logout(){
    return request({
        url:"/sso/logout",
        method:'post',
    })
}

//提交ticket
export function submitTicket(data){
    return request({
        url:"/sso/login",
        method:'post',
        data
    })
}

// 获取个人信息
export function studentInfo(){
    return request({
        url:"/student/getInfo",
        method:'get',
    })
}

// 更新个人信息
export function studentUpdateInfo(data){
    return request({
        url:"/student/updateInfo",
        method:'post',
        data
    })
}

// 课程列表
export function coursePage(data){
    return request({
        url:"/course/page",
        method:'post',
        data
    })
}

// 课程详情
export function courseDetail(id){
    return request({
        url:`/course/get/${id}`,
        method:'post',
    })
}

// 申请虚拟机
// export function vmApply(){
//     return request({
//         url:`/vm/apply`,
//         method:'post',
//     })
// }
export function vmApply(id){
    return request({
        url:`/student/applyVm/${id}`,
        method:'post',
    })
}

//结束使用虚拟机
// export function vmEndUse(id){
//     return request({
//         url:`/vm/endUse/${id}`,
//         method:'post',
//     })
// }
export function vmEndUse(id){
    return request({
        url:`/student/endUseVm/${id}`,
        method:'post',
    })
}

// 绑定步骤一
export function studentAuthBind(data){
    return request({
        url:"/student/authBind",
        method:'post',
        data
    })
}
// 绑定步骤二
export function studentAddBind(data){
    return request({
        url:"/student/addBind",
        method:'post',
        data
    })
}

// 解绑
export function studentRemoveBind(data){
    return request({
        url:"/student/removeBind",
        method:'post',
        data
    })
}

// 提交实验
export function studentSubmit(id){
    return request({
        url:`/student/submit/${id}`,
        method:'post',
    })
}

// 查看实验结果
export function studentGetReviewResult(id){
    return request({
        url:`/student/getReviewResult/${id}`,
        method:'post',
    })
}
