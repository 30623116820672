<template>
    <div class="list">
        <div class="middle">
            <div class="section">
                <div class="text">
                    <div>实践课程</div>
                    <div>实践型MOOC，让您的在线课程更专业</div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="section">
                <div class="content_top">
                    <el-input v-model="Info.name" placeholder="课程搜索" clearable></el-input>
                    <el-button type="primary" @click="getCoursePage">搜索</el-button>
                </div>
                <div class="content_middle">
                    <div class="Item" v-for="(item,index) in list" :key="index" @click="goDetail(item.id)">
                        <img v-if="item.picture" :src="url+item.picture.fileUrl" alt="">
                        <div class="itemTitle">{{item.name}}</div>
                    </div>
                </div>
                <el-pagination
                    v-if="total>0"
                    :total="total"
                    class="hidden-xs-only"
                    :page-size="Info.pageSize"
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    background
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import {coursePage} from '../../api/management.js'
export default {
    data() {
        return {
            url:process.env.VUE_APP_WEB_URL,
            total:0,
            Info:{
                pageSize:6,
                current:1,
                name:'',
                usable:true
            },
            list:[],
        }
    },
    created() {
        this.getCoursePage()
    },
    methods:{
        // 获取课程列表
        async getCoursePage() {
            const res = await coursePage(this.Info)
            if(res.code==1) {
                this.list = res.data.records
                this.total = res.data.total
            }
        },
        goDetail(id) {
            this.$router.push({path:'/listDetail',query:{id:id}})
        },
        handleCurrentChange(value) {
            this.Info.current = value
            this.getCoursePage()
        },
    },
    mounted() {
        // this.getCoursePage()
    }
}
</script>
<style lang="scss">
.list {
    .middle {
        height: 310px;
        background: url('../../assets/image/index/index.png') no-repeat center center;
        background-size: cover;
        .section {
            height: 310px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .text {
                color: #fff;
                font-size: 36px;
                line-height: 60px;
            }
        }
    }
    .content{
        .section {
            .content_top {
                padding: 50px 0;
                display: flex;
                .el-input {
                    height: 64px;
                    width: 300px;
                    .el-input__inner {
                        text-align: center;
                        height: 64px;
                        line-height: 64px;
                        font-size: 24px;
                    }
                    margin-right: 20px;
                }
                .el-button{
                    font-size: 24px;
                    height: 64px;
                    width: 100px;
                    background-color: #014da1;
                }
            }
            .content_middle {
                display: flex;
                flex-wrap: wrap;
                .Item{
                    padding: 10px;
                    width: 350px;
                    // height: 306px;
                    margin-right: 48px;
                    box-shadow: 0 0 28px -8px #cccccc;
                    margin-bottom: 45px;
                    img {
                        width: 100%;
                        height: 200px;

                    }
                    .itemTitle {
                        font-size: 20px;

                    }
                    .itemSchool {
                        color: #666666;
                        font-size: 16px;
                    }
                }
                .Item:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }

    }
}
</style>