<template>
    <div class="home">
        <div class="top">
            <div class="section">
                <div class="left point"  @click="goList">远程教学云桌面首页</div>
                <div class="right">
                    <!-- <div class="tarbar">虚拟机管理</div>
                    <div class="tarbar">文件传输</div>
                    <div class="tarbar">系统状态</div> -->
                    <div class="tarbar point" @click="getUrl" v-if="!userName">登录/注册</div>
                    <div class="tarbar point" v-if="userName">
                      <el-dropdown>
                        <span class="el-dropdown-link">
                          {{userName}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item @click.native="goPerson">个人中心</el-dropdown-item>
                          <el-dropdown-item @click.native="goOut">退出</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <router-view :key="key"></router-view>
        </div>
        <div class="bottom">
            <div class="section">
                <div class="bottom_left">
                    <div>联系我们</div>
                    <div>咨询电话：13866153618</div>
                    <div>E-mail：XXXXXX@ustc.edu.cn</div>
                    <div>地址：安徽省合肥市XX区XX</div>

                </div>
                <div class="bottom_middle">
                    <div>常用功能</div>
                    <div>FPGA实验</div>
                    <div>VerilogOJ学校</div>
                    <div>远程教学云桌面</div>
                    <div>信息咨询</div>
                    <div>实验教程</div>
                    <div>关于平台</div>
                </div>
                <div class="bottom_right">
                    <div>官方合作</div>
                    <div>XXX学会</div>
                    <div>XXX大学</div>
                    <div>XXX大学</div>
                    <div>XXX学会</div>
                    <div>XXX基地</div>
                    <div>XXX基地</div>
                </div>
                <div class="bottom_code">
                    <img src="../assets/image/index/qrcode.png" alt="">
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { authUrl,submitTicket,studentInfo,coursePage,logout } from '../api/management'
import { getToken,setToken,removeToken } from '../utils/token'
export default {
    computed:{
        key() {
            return this.$route.path + Math.random()
        },
        changeUserName() {
            return this.$store.state.userName;
        },
    },
    watch: {
        changeUserName: function (val) {
            this.userName = val;
        },
    },
    data() {
        return {
            path:this.$route.path,
            data:{
                ticket:''
            },
            userName: this.$store.state.userName,
            datareDirect:{
            //    redirect:encodeURI('http://36.150.108.157:8082')
                redirect:encodeURI('http://www.vlab.com.cn/#/list')
            },
        }
    },
    created(){
        if(this.userName) {
            this.getStudentInfo()
        }
    },
    methods:{
        goList() {
           this.$router.push('/list')
        },
        async getUrl() {
            const res = await authUrl(this.datareDirect)
            if(res.code==1) {
              window.open(res.data,'_self')
            }
        },
        getTicket() {
            let query = window.location.search
            if(query) {
                if(!getToken()) {
                    this.data.ticket = query.slice(8)
                    this.getSubmitTicket()
                }
            }
        },
        // 去登录 //储存tokenValue
        async getSubmitTicket() {
            const res = await submitTicket(this.data)
            if(res.code==1) {
              setToken(res.data.tokenValue)
              this.getStudentInfo()
              this.getDelUrl()
            }
        },
        //获取个人信息
        async getStudentInfo() {
            const res = await studentInfo()
            if(res.code==1) {
                this.$store.commit("changeUserName", res.data.username)
            }
            // if(res==undefined) {
            //     this.$message({
            //         message: '请重新登录',
            //         type: 'error',
            //     })
            //     removeToken()
            //     sessionStorage.removeItem('userName')
            //     this.userName = ''
            //     this.$router.push('/list')
            // } else {
            //     if(res.code==1) {
            //         this.$store.commit("changeUserName", res.data.username)
            //     }
            // }
        },
        // 退出
        async goOut() {
            const res = await logout()
            if(res.code==1) {
                this.$message({
                    message: '已退出',
                    type: 'success'
                });
                removeToken()
                this.$store.commit("changeUserName", '');
                this.$router.push('/list')
            }
        },
        getDelUrl() {
            let url = window.location.href
            let url1 = url.substring(0,window.location.href.indexOf("?"))
            let url2 = url.substring(window.location.href.indexOf("#"))
            let newUrl = url1 + url2
            window.history.replaceState(null, null, newUrl)
        },
        // 个人信息页面
        goPerson() {
            this.$router.push('/personal')
        }
    },
    mounted() {
      this.getTicket()
    }
}
</script>
<style lang="scss">
.home {
    background-color: #fafafa;
    .top{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        height: 71px;
        display: flex;
        align-items: center;
        background-color: #fafafa;
        width: 100%;
        .section {
            height: 71px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 71px;
                width: 163px;
                font-size: 16px;
                background-color: #014DA1;
                color: #fff;
            }
            .point {
              cursor: pointer;
            }
            .right {
                font-size: 16px;
                display: flex;
                .tarbar {
                    margin: 0 20px;
                }
                .tarbar:nth-child(4) {
                    margin-right: 0;
                }
            }
        }
    }
    .content {
        margin-top: 71px;
    }
    .bottom {
        background-color: #014DA1;
        .section {
            padding: 20px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size:14px;
            color: #fff;
            .bottom_left {
                display: flex;
                flex-direction: column;
                line-height: 26px;
            }
            .bottom_middle {
                display: flex;
                flex-direction: column;
                line-height: 26px;
            }
            .bottom_right{
                display: flex;
                flex-direction: column;
                line-height: 26px;

            }
            .bottom_code {
                width: 136px;
                height: 136px;
            }

        }
    }
}
</style>