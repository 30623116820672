<template>
  <div id="app">
    <router-view :key="key"></router-view>
  </div>
</template>
<script>
// import { getToken,removeToken } from './utils/token'
// import { logout } from './api/management'
export default {
  computed:{
        key() {
            return this.$route.path + Math.random()
        }
    },
  data() {
    return {
      // lastTime: null,
      // //10分钟未检测到鼠标点击就退出登录，后台正常校验20h
      // timeOut: 1000*4*60,
    }
  },
  created() {
    // this.lastTime = new Date().getTime()
  },
  methods: {
    // //全局监听得点击事件
    // isTimeOut() {
    //   var currentTime = new Date().getTime();
    //   // 判断上次最后一次点击的时间和这次点击的时间间隔
    //    if (currentTime - this.lastTime > this.timeOut) {
    //     // 判断有没有登录
    //     if(getToken()) {
    //         this.goOut()
    //     }
    //   } else {
    //     // 如果在期限内点击，则把这次点击的时间覆盖掉之前存的最后一次点击的时间
    //     this.lastTime = new Date().getTime()
    //   }
    // },
    // // 退出
    // async goOut() {
    //     const res = await logout()
    //     // console.log(res);
    //     if(res.code==1) {
    //         this.$message({
    //             message: '长时间未操作，已退出，请重新登录',
    //             type: 'success'
    //         });
    //         removeToken()
    //         sessionStorage.removeItem('userName')
    //         // this.userName = ''
    //         this.$router.push('/list')
    //         this.getDelUrl()
    //     } else {
    //         this.$message({
    //             message: '长时间未操作，已退出，请重新登录',
    //             type: 'success'
    //         });
    //         removeToken()
    //         sessionStorage.removeItem('userName')
    //         // this.userName = ''
    //         this.$router.push('/list')
    //         this.getDelUrl()
    //     }
    // },
    // getDelUrl() {
    //     let url = window.location.href
    //     let url1 = url.substring(0,window.location.href.indexOf("?"))
    //     let url2 = url.substring(window.location.href.indexOf("#"))
    //     let newUrl = url1 + url2
    //     window.history.replaceState(null, null, newUrl)
    // },
  },
}
</script>

<style lang="scss">

</style>
