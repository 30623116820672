<template>
	<div class="box" ref="box">
        <div class="header_top">
          <div class="top_left">
              <img src="../../assets/image/index/header.png" alt="">
              <div class="userName">{{userName}}</div>
          </div>
          <div class="top_middle">
              <div class="top_middle_title">{{title}}</div>
              <div class="top_middle_timer">{{timeText}}</div>
          </div>
          <div class="top_right">
              <el-button type="primary" @click="openExamDialog">提交审核</el-button>
              <img @click="close()" src="../../assets/image/index/close.png" alt="">
          </div>
        </div>
		    <div class="contents">
          <div class="left">
            <div class="left_top">
                <div class="left_top_img">
                    <img @click="drawer = true" class="moreImg" src="../../assets/image/index/more.png" alt="">
                </div>
            </div>
            <div class="left_content" v-html="detail.content"></div>
          </div>
          <div class="resize" title="收缩侧边栏">
            ⋮
            <!-- <div class="resizePoint">⋮</div> -->
          </div>
          <div class="mid">
            <!--右侧div内容-->
            <div v-if="toDrag" class="dragHand-resize-shadow"></div>
            <!-- <iframe src="http://192.168.5.100:6080/vnc.html" id="mobsf" scrolling="no" frameborder="0"></iframe> -->
            <iframe :src="vmApply" id="mobsf" scrolling="no" frameborder="0"></iframe>
          </div>
        </div>
        <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose">
            <span>虚拟机使用时间已到，请确认退出</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="outVm">确 定</el-button>
            </span>
        </el-dialog>
        <el-drawer
            :with-header="false"
            :visible.sync="drawer"
            :direction="direction">
            <div class="coursewareVoList_item" :class="{active: item.id == valueId}" v-for="(item,index) in experimentList" :key="index"
            @click="goNvcItem(item)"
            >
                <div>{{index+1}}、</div>
                <div class="item_name">{{item.name}}</div>
            </div>
        </el-drawer>
        <!-- 审核弹框 -->
        <el-dialog
            title="提示"
            :visible.sync="centerDialogVisible"
            width="30%"
            center>
            <span style="font-size:16px;">请确认是否已完成实验并提交审核？</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" style="margin-right:20px;" @click="submitExam">确 定</el-button>
                <el-button @click="centerDialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
	</div>
</template>
<script>
import {courseDetail,vmEndUse,studentSubmit } from '../../api/management'
import RFB from '@novnc/novnc/core/rfb';
import axios from 'axios';
import {vmApply} from '../../api/management.js'
export default {
  data() {
    return {
      toDrag:false,
      userName:sessionStorage.getItem('userName'),
      title:this.$route.query.name,
      timeText:'',
      timeInterval: null,
      vmApply:'',
      id:this.$route.query.id,
      valueId:this.$route.query.valueId,
      detail:{},
      vmId:'',//虚拟机id
      planEndTime:'',//虚拟机结束时间
      dialogVisible:false,
      drawer: false,
      direction: 'ltr',
      experimentList:[],

      centerDialogVisible:false,//审核弹框
    }
  },
  mounted() {
    this.dragControllerDiv();
  },
  created() {
    this.getVmApply(this.valueId)
    this.getCourseDetail(this.id)
  },
  methods:{
    async getCourseDetail(id) {
        const res = await courseDetail(id)
        if(res.code == 1) {
          let list = res.data.coursewareVoList
          this.experimentList = res.data.coursewareVoList
            list.forEach(item => {
              if(item.id==this.valueId) {
                this.detail = item
              }
            });
        }
    },
    async getVmApply(valueId) {
        const res = await vmApply(valueId)
        if(res.code==1) {
           this.vmApply = res.data.addr
           this.vmId = res.data.id
           this.planEndTime = res.data.curr.planEndTime
           this.timing1()
        }
    },
    dragControllerDiv () {
      var resize = document.getElementsByClassName('resize');
      var left = document.getElementsByClassName('left');
      var mid = document.getElementsByClassName('mid');
      var box = document.getElementsByClassName('box');
      let that = this
      for (let i = 0; i < resize.length; i++) {
        // 鼠标按下事件
        resize[i].onmousedown = function (e) {
          //颜色改变提醒
          resize[i].style.background = '#818181';
          var startX = e.clientX;
          resize[i].left = resize[i].offsetLeft;
          // 鼠标拖动事件
          document.onmousemove = function (e) {
              that.toDrag = true
              var endX = e.clientX;
              var moveLen = resize[i].left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
              var maxT = box[i].clientWidth - resize[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度
              if (moveLen < 32) moveLen = 32; // 左边区域的最小宽度为32px
              if (moveLen > maxT - 150) moveLen = maxT - 150; //右边区域最小宽度为150px
              resize[i].style.left = moveLen; // 设置左侧区域的宽度
              for (let j = 0; j < left.length; j++) {
                  left[j].style.width = moveLen + 'px';
                  mid[j].style.width = (box[i].clientWidth - moveLen - 10) + 'px';
              }
          };
          // 鼠标松开事件
          document.onmouseup = function (evt) {
              that.toDrag = false
              //颜色恢复
              resize[i].style.background = '#d6d6d6';
              document.onmousemove = null;
              document.onmouseup = null;
              resize[i].releaseCapture && resize[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
          };
          resize[i].setCapture && resize[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
          return false;
        };
      }
    },
    timing1 () {
      let nowTime = new Date().getTime()
      let endTime = new Date(this.planEndTime).getTime()
      let time = (endTime - nowTime) / 1000
      this.timeInterval = setInterval(() => {
        let hour = parseInt(time / 3600)
        let minute = parseInt(time / 60 % 60)
        let second = parseInt(time % 60)
        this.timeText = this.toDouble(hour) + ':' + this.toDouble(minute) + ':' + this.toDouble(second)
        time--
        if(time<=0) {
          clearInterval(this.timeInterval);
          this.dialogVisible = true
        }
      }, 1000)
    },
    // 时间补0
    toDouble (n) {
        if (n < 10) {
            return '0' + n
        } else {
            return '' + n
        }
    },
    close() {
      this.$confirm('您确定要退出实训吗?','退出实训', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
          }).then(() => {
            // 清除定时器
            this.getVmEndUse()
            clearInterval(this.timeInterval)
          }).catch(() => {
      });
    },
    async getVmEndUse() {
      const res = await vmEndUse(this.vmId)
      if(res.code==1) {
        this.$message({
            message: '虚拟机已退出',
            type: 'success',
        })
        setTimeout(() => {
           this.$router.push({path:'/listDetail',query:{id:this.id}})
        }, 1000);
      }
    },
    outVm() {
      this.getVmEndUse()
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.getVmEndUse()
        })
        // .catch(_ => {});
    },
    // 选择其他实验
    goNvcItem(item) {
        this.drawer = false
        this.title = item.name
        this.valueId = item.id
        this.$router.push({path:'/experiment',query:{id:this.id,name:this.title,valueId:this.valueId}})
        this.getCourseDetail(this.id)
        this.getVmApply(item.id)
    },
    // 审核弹框
    openExamDialog() {
      this.centerDialogVisible = true
    },
    async submitExam() {
      const res = await studentSubmit(this.vmId)
      if(res.code==1) {
        this.$message({
            message: '提交成功',
            type: 'success'
        });
        this.getVmEndUse()
        clearInterval(this.timeInterval)
      }
    },
  },
  destroyed() {
    clearInterval(this.timeInterval)
  }
};
</script>
<style lang="scss">
  /* 拖拽相关样式 */
    /*包围div样式*/
    .box {
        width: 100%;
        height: 100%;
        .header_top {
          padding: 0 20px;
          background-color: #05101a;
          height: 56px;
          color: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .top_left {
            display: flex;
            align-items: center;
            img {
              width: 35px;
              height: 35px;
            }
            .userName {
              margin-left: 10px;
              font-size: 16px;
            }
          }
          .top_middle {
            display: flex;
            flex-direction: column;
            align-items: center;
            .top_middle_title {
              font-size: 16px;
            }
            .top_middle_timer {
              margin-top: 4px;
              font-size: 13px;
            }

          }
          
          .top_right {
            display: flex;
            align-items: center;
            .el-button--primary {
              background-color: #014da1;
              margin-right: 15px;
              border-color: #014da1;
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
        .contents {
          width: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;
          box-shadow: -1px 9px 10px 3px rgba(0, 0, 0, 0.11);
          /*左侧div样式*/
          .left {
              width: calc(32% - 10px);  /*左侧初始化宽度*/   
              height: calc(100vh - 56px);
              background: #FFFFFF;
              // float: left;
              overflow-y: auto;
              padding: 0 20px 20px 0;
              .left_top {
                .left_top_img {
                    width: 45px;
                    height: 45px;
                    background-color: #4cacff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .moreImg {
                        height: 30px;
                        width: 30px;
                    }
                }
              }
              // .left_top {
              //   .left_top_text {
              //     display: flex;
              //     justify-content: space-between;
              //     align-items: center;
              //     .left_one {
              //       font-size: 40px;
              //       color: #5d5d5d;
              //       font-weight: 200;
              //     }
              //     .right_one {
              //       img {
              //         width: 28px;
              //         height: 28px;
              //       }
              //     }
              //   }
              // }
              // .left_middle_top {
              //   margin-top: 40px;
              //   width: 100%;
              //   border: 2px solid #57d98c;
              //   border-radius: 6px;
              //   .middle_top_text {
              //     display: flex;
              //     background-color: #ebfaee;
              //     align-items: center;
              //     padding: 5px 0;
              //     img {
              //       padding: 0 10px;
              //     }
              //     .start {
              //       font-size: 16px;
              //       font-weight: bold;

              //     }
              //   }
              //   .middle_bottom_text {
              //     padding: 20px 0 15px 10px;
              //     .start {
              //       font-size: 17px;
              //       .blue {
              //         color: #2094f3;
              //       }
              //     }
              //   }
              //   .template {
              //     padding: 0 0 20px 10px;
              //     font-size: 17px;
              //   }
              //   .example {
              //     padding: 0 0 25px 10px;
              //     display: flex;
              //     .example_left {
              //       height: 90px;
              //       width: 5px;
              //       background-color: #adadad;
              //       margin-right: 10px;
              //     }
              //     .example_right {
              //       padding: 2px 0;
              //       display: flex;
              //       flex-direction: column;
              //       justify-content: space-between;
              //       font-size: 16px;
              //       color: #adadad;
              //     }
              //   }
              // }
              // .left_middle_bottom {
              //   margin-top: 25px;
              //   .title {
              //     font-size: 18px;
              //     line-height: 30px;
              //   }
              // }
              // .left_talk {
              //   margin-top: 25px;
              //   width: 100%;
              //   border: 2px solid #7fadfc;
              //   border-radius: 6px;
              //   .middle_top_text {
              //     display: flex;
              //     background-color: #edf3ff;
              //     align-items: center;
              //     padding: 7px 0;
              //     .images {
              //       height: 25px;
              //       width: 25px;
              //       background-color: #448aff;
              //       border-radius: 100%;
              //       margin: 0 10px;
              //       display: flex;
              //       justify-content: center;
              //       align-items: center;
              //       img {
              //         width: 15px;
              //         height: 15px;
              //       }
              //     }
              //     .start {
              //       font-size: 16px;
              //       font-weight: bold;
              //     }
              //   }
              //   .middle_bottom_text {
              //     padding: 0 0 15px 10px;
              //     .start {
              //       font-size: 17px;
              //       margin-top: 20px;
              //       line-height: 30px;
              //       .blue {
              //         color: #2094f3;
              //       }
              //       .line {
              //         text-decoration: line-through;
              //       }
              //       .bold {
              //         font-weight: bold;
              //       }
              //     }
              //   }
              // }
              // .middle_image {
              //   margin: 20px 0;
              //   img {
              //     width: 100%;
              //   }
              // }
              /* 定义滚动条样式 */
              &::-webkit-scrollbar {
                  width: 6px;
                  height: 6px;
                  background-color: rgba(240, 240, 240, 1);
              }
              /*定义滚动条轨道 内阴影+圆角*/
              &::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
                  border-radius: 10px;
                  background-color: rgba(240, 240, 240, .5);
              }
              /*定义滑块 内阴影+圆角*/
              &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  box-shadow: inset 0 0 0px rgba(173, 173, 173, .5);
                  background-color: rgba(173, 173, 173, .5);
              }
              .left_content {
                padding-left: 20px;
                li {
                   list-style:none;
                   font-size: 18px;
                   cursor: pointer;
                }
                a {
                  color:#1e88da;
                }
                .center {
                   text-align: center;
                }
                .margin {
                  margin-left: 20px;
                }
              }
          }
          /*拖拽区div样式*/
          .resize {
              cursor: col-resize;
              // float: left;
              position: relative;
              top: 45%;
              background-color: #d6d6d6;
              // margin-top: -10px;
              border-radius: 5px;
              width: 10px;
              height: 50px;
              background-size: cover;
              background-position: center;
              /*z-index: 99999;*/
              font-size: 32px;
              color: white;
          }
          /*拖拽区鼠标悬停样式*/
          .resize:hover {
              color: #444444;
          }
          /*右侧div'样式*/
          .mid {
              width: 68%;   /*右侧初始化宽度*/
              height: calc(100vh - 56px);
              background: #fff;
              box-shadow: -1px 4px 5px 3px rgba(0, 0, 0, 0.11);
              position: relative;
              #mobsf {
                width: 100%!important;
                height: 100%!important;
              }
              .dragHand-resize-shadow{ 
                position: absolute; 
                left: 0; 
                right: 0; 
                top: 0; 
                bottom: 0; 
                background: rgba(0,0,0,0.1); 
              }
          }
        }
        .el-drawer__wrapper {
            top:56px;
            .el-drawer__open {
                .el-drawer {
                    .el-drawer__body{
                      background: #111c24;
                      .coursewareVoList_item {
                          padding-left: 20px;
                          display:flex;
                          height: 60px;
                          line-height: 60px;
                          color: #bcc6cd;
                          font-size: 16px;
                          cursor: pointer;
                          border-bottom: 1px solid #222c34;
                      }
                      .active {
                          color: #fff;
                          background: #0152d9;
                      }
                    }
                }
            }
        }
    }
</style>
