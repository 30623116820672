import Cookies from 'js-cookie'

const TokenKey = 'vue_admin_template_token'

// get
export function getToken() {
    return Cookies.get(TokenKey)
}

// set
export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

// remove
export function removeToken() {
    return Cookies.remove(TokenKey)
}
