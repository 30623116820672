<template>
    <div class="my">
        <div class="section">
            <div class="my_top">个人中心</div>
            <div class="my_content">
                <div class="content_top">
                    <div class="content_top_top">基本信息</div>
                    <div class="content_top_content">
                        <el-form ref="form" :model="form" label-width="120px" label-position="left">
                            <el-form-item label="用户名：" prop="username">
                                <el-input v-model="form.username"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号：" prop="phone">
                                <el-input v-model="form.phone"></el-input>
                            </el-form-item>
                            <el-form-item label="邮箱：" prop="email">
                                <el-input v-model="form.email"></el-input>
                            </el-form-item>
                            <el-form-item label='密码:' prop="password">
                                <el-input placeholder="请输入密码" auto-complete="new-password" type="password" v-model="form.password" clearable show-password></el-input>
                            </el-form-item>
                            <el-form-item label="确认密码:" prop="checkPass">
                                <el-input type="password" auto-complete="new-password" v-model="form.checkPass" show-password autocomplete="off" placeholder="请再次输入密码"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="content_middle">
                    <div class="content_middle_top">绑定登录账号信息</div>
                    <div class="content_middle_content">
                        <div class="wx">
                            <div class="left">
                                <img src="../../assets/image/index/wx.png" alt="">
                                <div>微信</div>
                            </div>
                            <div class="right">
                                <div v-if="form.wx==1" @click="unBinding(1)">解绑</div>
                                <div v-if="form.wx==0" @click="binding(1)">绑定</div>
                            </div>
                        </div>
                        <div class="qq">
                           <div class="left">
                                <img src="../../assets/image/index/qq.png" alt="">
                                <div>QQ</div>
                            </div>
                            <div class="right">
                                <div v-if="form.qq==1" @click="unBinding(2)">解绑</div>
                                <div v-if="form.qq==0" @click="binding(2)">绑定</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content_bottom">
                    <div class="button sure" @click="submit">确定</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { studentInfo,studentUpdateInfo,studentAuthBind,studentAddBind,studentRemoveBind } from '../../api/management'
import { getToken,setToken } from '../../utils/token'
export default {
    computed:{
        changeUserName() {
            return this.$store.state.userName;
        },
    },
    watch: {
        changeUserName: function (val) {
            this.userName = val;
        },
    },
    data() {
        return {
            userName: this.$store.state.userName,
            form :{
                username:'',
                phone:'',
                email:'',
                wx:'',
                qq:'',
                uniqueId:'',
                password:'',
                checkPass:''
            },
            rules: {
                username: [
                    { required: true, message: '用户名不能空', trigger: 'blur' },
                ],
                phone:[
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    {
                            pattern:/^1[3456789]\d{9}$/,
                            message: "请输入正确的手机号",
                            trigger: "blur",
                    },
                ],
                email: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ],
            },
            bindType:''
        }
    },
    created() {
        if(this.userName) {
            this.getStudentInfo()
            this.getCheckUrl()
        }
    },
    methods:{
        //获取个人信息
        async getStudentInfo() {
            const res = await studentInfo()
            if(res.code==1) {
                this.form.username = res.data.username
                this.form.phone = res.data.phone
                this.form.email = res.data.email
                this.form.wx = res.data.wx
                this.form.qq = res.data.qq
                this.form.uniqueId = res.data.uniqueId
                this.$store.commit("changeUserName", res.data.username);
            }
        },
        submit() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    if(this.form.password) {
                        if(this.form.password.length<6) {
                            return this.$message({
                                message: '密码至少多于6位',
                                type: 'error',
                            })
                        }
                        if(this.form.checkPass!==this.form.password) {
                            return this.$message({
                                message: '确认密码与密码不一致',
                                type: 'error',
                            })
                        }
                        const res = await studentUpdateInfo(this.form)
                        if(res.code==1) {
                            this.$message({
                                message: res.msg,
                                type: 'success',
                            })
                            this.getStudentInfo()
                            setTimeout(() => {
                                this.$router.push('/list')
                            }, 2000);
                        }
                    } else {
                        const res = await studentUpdateInfo(this.form)
                        if(res.code==1) {
                            this.$message({
                                message: res.msg,
                                type: 'success',
                            })
                            this.getStudentInfo()
                            setTimeout(() => {
                                this.$router.push('/list')
                            }, 2000);
                        }
                    }
                    
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 绑定
        async binding(types) {
            let type = types
            let url = window.location.href
            if(type==1) {
                this.bindType = 'wx_open'
            } else {
                this.bindType = 'qq'
            }
            const res = await studentAuthBind(
                {
                    redirect:url,
                    bindType:this.bindType
                }
            )
            if(res.code==1) {
                window.open(res.data,'_self')
            }
        },
        // 
        async getCheckUrl() {
            let url = window.location.href
            if(url.indexOf('uniqueId')>0) {
                let params = this.getQueryParams(url)
                const res = await studentAddBind(
                    {
                        uniqueId:params.uniqueId,
                        bindType:params.bindType
                    }
                )
                if(res.code==1) {
                    this.$message({
                        message: '绑定成功',
                        type: 'success'
                    });
                    setToken(res.data.tokenValue)
                    this.getDelUrl()
                    this.getStudentInfo()
                }
            }
        },
        
        getQueryParams(url) {
            const paramArr = url.slice(url.indexOf("?") + 1).split("&");
            const params = {};
            paramArr.map((param) => {
                const [key, val] = param.split("=");
                params[key] = decodeURIComponent(val);
            });
            return params;
        },
        getDelUrl() {
            let url = window.location.href
            let newUrl = url.substring(0,window.location.href.indexOf("?"))
            window.history.replaceState(null, null, newUrl)
        },
        // 解绑
        async unBinding(types) {
            let type = types
            if(type==1) {
                this.bindType = 'wx_open'
            } else {
                this.bindType = 'qq'
            }
            const res = await studentRemoveBind(
                {
                    bindType:this.bindType
                }
            )
            if(res.code==1) {
                this.$message({
                    message: '解绑成功',
                    type: 'success',
                })
                this.getStudentInfo()
            }

        },
    }
}
</script>
<style lang="scss">
.my {
    background-color: #fff;
    .section {
        .my_top {
            font-size: 20px;
            padding: 30px 100px;
            border-bottom: 1px solid #fafafa;
            color: #101010;
        }
        .my_content {
            .content_top {
                padding: 30px 100px;
                border-bottom: 1px solid #fafafa;
                font-size: 20px;
                .content_top_top {
                    margin-bottom: 30px;
                }
                .content_top_content {
                    .el-form {
                        .el-form-item {
                            .el-form-item__label {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            .content_middle {
                font-size: 20px;
                padding: 30px 100px;
                border-bottom: 1px solid #fafafa;
                .content_middle_top {
                    margin-bottom: 30px;
                }
                .content_middle_content {
                    .wx, .qq {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .left {
                            display: flex;
                            align-items: center;
                            img {
                                width: 40px;
                                margin-right: 10px;
                            }
                        }
                        .right {
                            >div {
                                color:#1e5ca8;
                                cursor: pointer;
                            }
                        }
                    }
                    .wx {
                        margin-bottom: 20px;
                    }
                }
            }
            .content_bottom {
                padding: 30px 100px;
                display: flex;
                justify-content: center;
                .button {
                    background-color: red;
                    font-size: 15px;
                    text-align: center;
                    width: 75px;
                    height: 40px;
                    line-height: 40px;
                    color: #fff;
                    cursor: pointer;
                }
                .sure {
                    background-color: #014da1;
                }
            }
        }
    }
}
</style>