<template>
    <div class="listDetail">
        <div class="middle">
            <div class="section">
                <div class="text">
                    <div>{{detail.name}}</div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="section">
                <div class="content_top">
                    <img src="../../assets/image/index/index_3.png" alt="">
                    <div class="content_top_text">课程介绍</div>
                </div>
                <div class="content_middle">
                    <div v-html="detail.content"></div>
                </div>
            </div>
        </div>
        <div class="lessonContent">
            <div class="section">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="实验项目" name="1">
                        <div class="first_content wow animate__animated animate__fadeIn">
                            <div class="coursewareVoList_item" v-for="(item,index) in detail.coursewareVoList" :key="index">
                                <div class="item_name" @click="goNvc(item)">{{item.name}}</div>
                                <div class="status blue" v-if="item.studyStatus==-1||item.studyStatus==null">待学习</div>
                                <div class="status blue" v-if="item.studyStatus==0">学习中</div>
                                <div class="status blue" v-if="item.studyStatus==1">已提交</div>
                                <div class="status green" v-if="item.studyStatus==2" @click="lookRemark(item.id)">已通过</div>
                                <div class="status red" v-if="item.studyStatus==3" @click="lookRemark(item.id)">未通过</div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <!-- <el-tab-pane label="实践项目" name="2">
                        <div class="second_content wow animate__animated animate__fadeIn">
                            暂无项目
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="视频项目" name="3">
                        <div class="third_content wow animate__animated animate__fadeIn">
                            暂无项目
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="教学课件" name="4">
                        <div class="four_content wow animate__animated animate__fadeIn">
                            暂无课件
                        </div>
                    </el-tab-pane> -->
                </el-tabs>
            </div>
        </div>
        <el-dialog
            title="审核结果"
            :visible.sync="centerDialogVisible"
            width="50%"
            center>
            <span v-html="remark"></span>
        </el-dialog>
    </div>
</template>
<script>
import { authUrl,courseDetail,studentGetReviewResult } from '../../api/management'
import { getToken,removeToken } from '../../utils/token'
export default {
    data() {
        return {
            activeName:'1',
            firstListOne:[],
            firstListTwo:[],
            data:{
                // redirect:encodeURI('http://36.150.108.35:8082')
                redirect:encodeURI('http://36.150.108.157:8080/#/list')
            },
            id:this.$route.query.id,
            detail:{},
            // 弹框
            centerDialogVisible: false,
            remark:'',//审核备注
        }
    },
    created(){
        this.getCourseDetail()
    },
    methods:{
        handleClick(tab) {
        //    console.log(tab);
        },
        goNvc(item) {
            let name = item.name
            let valueId = item.id
            let userName = sessionStorage.getItem('userName')
            if(getToken() && userName) {
                this.$router.push({path:'/experiment',query:{id:this.id,name:name,valueId:valueId}})
            } else {
                this.$message({
                    message: '未登录，请登录',
                    type: 'error',
                })
                removeToken()
                sessionStorage.removeItem('userName')
                setTimeout(() => {
                    this.$router.push('/list')
                }, 1000);
            }
        },
        goList() {
           this.$router.push('/list')
        },
        async getUrl() {
            const res = await authUrl(this.data)
            // console.log(res);
            if(res.code==1) {
              window.open(res.data,'_self')
            }
        },
        async getCourseDetail() {
            const res = await courseDetail(this.id)
            // console.log(res);
            if(res.code == 1) {
                this.detail = res.data
            }
        },
        async lookRemark(id) {
            this.centerDialogVisible = true
            const res = await studentGetReviewResult(id)
            if(res.code==1) {
                this.$nextTick(()=>{
                    this.remark = res.data.remark.replace(/<img/gi,'<img style="max-width:100%;height:auto;display:inline-block;margin:10px auto;"')
                })
            }
        }
    },
    mounted() {
    //   this.getTicket()
    }
}
</script>
<style lang="scss">
.listDetail {
    background-color: #fafafa;
    .middle {
        height: 310px;
        background: url('../../assets/image/index/index.png') no-repeat center center;
        background-size: cover;
        .section {
            height: 310px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .text {
                color: #fff;
                font-size: 36px;
                line-height: 60px;
            }
        }
    }
    .content{
        margin-top: 25px;
        .section {
            background-color: #fff;
            padding: 30px 25px;
            .content_top {
                padding: 0 0 20px 0;
                display: flex;
                align-items: center;
                img {
                    width: 36px;
                    height: 37px;
                    margin-right: 20px;
                }
                .content_top_text {
                    font-size: 34px;
                }


            }
            .content_middle {
                font-size: 18px;
                .middle_text{
                    line-height: 30px;
                }
            }
        }
    }
    .lessonContent {
        .section {
            .el-tabs {
                .el-tabs__header {
                    margin: 0;
                    line-height: 90px;
                    .el-tabs__nav-wrap {
                        .el-tabs__nav-scroll {
                        .el-tabs__nav{
                            .el-tabs__item {
                            font-size: 32px;
                            padding: 0 40px
                            }
                            .el-tabs__item:hover {
                            color: #014DA1;
                            }
                            #tab-1 {
                            padding-left: 0!important;
                            }
                            .el-tabs__active-bar {
                            height: 4px;
                            background-color: #014DA1;
                            }
                            .is-active {
                            color: #014DA1;
                            }
                        }
                        }
                    }
                }
                .el-tabs__content {
                    .first_content {
                        padding: 20px 0;
                        .first_content_one {
                            margin-top: 30px;
                            padding: 30px 25px;
                            background-color: #fff;
                            .content_one_top {
                                display: flex;
                                align-items: center;
                                margin-bottom: 20px;
                                img {
                                    width: 26px;
                                    height: 30px;
                                }
                                .lesson_text {
                                    font-size: 34px;
                                }
                                .one{
                                    margin: 0 15px;
                                }
                            }
                            .content_one_middle{
                                .lesson_text {
                                    font-size: 18px;
                                    line-height: 30px;
                                }
                            }
                            .content_one_bottom {
                                margin-top: 20px;
                                .item{

                                    .itemTitle {
                                        font-size: 18px;
                                        line-height: 48px;
                                        font-weight: 600;
                                    }
                                }
                                .item:hover{
                                    cursor: pointer;
                                    .itemTitle {
                                        color: #014DA1 ;
                                    }

                                }

                            }

                        }
                        .first_content_two {
                            margin-top: 30px;
                            padding: 30px 25px;
                            background-color: #fff;
                            .content_one_top {
                                display: flex;
                                align-items: center;
                                margin-bottom: 20px;
                                img {
                                    width: 26px;
                                    height: 30px;
                                }
                                .lesson_text {
                                    font-size: 34px;
                                }
                                .one{
                                    margin: 0 15px;
                                }
                            }
                            .content_one_middle{
                                .lesson_text {
                                    font-size: 18px;
                                    line-height: 30px;
                                }
                            }
                            .content_one_bottom {
                                margin-top: 20px;
                                .item{
                                    .itemTitle {
                                        font-size: 18px;
                                        line-height: 48px;
                                        font-weight: 600;
                                    }
                                }
                                .item:hover{
                                    cursor: pointer;
                                    .itemTitle {
                                        color: #014DA1 ;
                                    }
                                }
                            }
                        }
                        .coursewareVoList_item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .item_name {
                                font-size: 18px;
                                line-height: 48px;
                                font-weight: 600;
                            }
                            .item_name:hover{
                                cursor: pointer;
                                color: #014DA1 ;
                            }
                            .status {
                                font-size: 16px;
                                font-weight: 600;
                            }
                            .blue {
                                color: #287bda;
                            }
                            .red {
                                color: #fb3939;
                                cursor: pointer;
                            }
                            .green {
                                color: green;
                                cursor: pointer;
                            }
                        }
                    }
                    .second_content {
                        height: 400px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 26px;
                    }
                    .third_content {
                        height: 400px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 26px;
                    }
                    .four_content {
                        height: 400px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 26px;
                    }
                }
            }
        }
    }
    .el-dialog {
        .el-dialog__body {
           min-height: 500px;
        }
    }
}
</style>