import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    num:sessionStorage.getItem('num'),
    userName:sessionStorage.getItem('userName')?sessionStorage.getItem('userName'):''
    
  },
  mutations: {
    //改变数字
    changeNum(state,val) {
      state.num = val
      window.sessionStorage.setItem('num',val)
    },
    // 改变名字
    changeUserName(state,val) {
      state.userName = val
      window.sessionStorage.setItem('userName',val)
    }
  },
  actions: {},
  modules: {},
});
