import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';

// 公共样式
import './assets/css/main.css'
import 'element-ui/lib/theme-chalk/index.css' //引用 element-ui 样式
import 'element-ui/lib/theme-chalk/display.css'

Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
